import { db, storage } from 'backend'
import uuid from 'uuid/v4'

export function savePhoto (id, subId, photoDataUrl) {
  const fileName = uuid() + '.png'
  return storage.ref(`photos/${id}/${subId}/${fileName}`).putString(photoDataUrl, 'data_url')
    .then(() => db.runTransaction(transaction => {
      const ref = db.collection('tasks').doc(id)
      return transaction.get(ref)
        .then(doc => {
          const { subtasks } = doc.data()
          const updatedSubtasks = subtasks.map(st => {
            if (st.id !== subId) {
              return st
            } else {
              const photos = st.photos || []
              return {
                ...st,
                photos: photos.concat([fileName])
              }
            }
          })
          return transaction.update(ref, { subtasks: updatedSubtasks })
        })
    }))
}
