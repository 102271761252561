import React, { useState, useCallback, useReducer } from 'react'
import loadImage from 'blueimp-load-image'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Button,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  FormControlLabel,
  Slide
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import CameraIcon from '@material-ui/icons/AddAPhotoOutlined'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import useStream from 'hooks/useStream'
import { streamSubTask, setState } from 'api/task'
import { savePhoto } from 'api/capture'
import uuid from 'uuid/v4'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(8)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  image: {
    margin: theme.spacing(1, 0),
    padding: 0,
    borderRadius: '5px',
    width: '100%'
  },
  images: {
    padding: theme.spacing(0, 1),
    textAlign: 'center'
  },
  message: {
    padding: theme.spacing(4, 2),
    fontSize: '1rem'
  },
  icon: {},
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    '& .icon': {
      marginLeft: theme.spacing(1)
    }
  },
  hiddenInput: {
    position: 'absolute',
    left: '-9999px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 2, 0),
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '""'
    }
  },
  title: {
    flexGrow: 1
  },
  bottomNav: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    bottom: 0, 
    left: 0,
    width: '100%'
  },
  openCamera: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  }
}))

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [action.id]: true
        }
      }
    case 'UPLOADED':
      return {
        ...state,
        uploading: {
          ...state.uploading,
          [action.id]: false
        }
      }
    default:
      return state
  }
}

export default () => {
  const classes = useStyles()
  const { id, subId } = useParams()
  const [showWarning, setShowWarning] = useState(true)
  const createStream = useCallback(() => streamSubTask(id, subId), [id, subId])
  const subtask = useStream(createStream, [])
  const [state, dispatch] = useReducer(reducer, { uploading: {} })
  const isUploading = Object.keys(state.uploading).filter(key => state.uploading[key]).length

  const handleSwitchChange = ({ target: { checked }}) => {
    const state = checked ? 'DONE' : 'REOPENED'
    setState(id, subId, state)
  }

  const handleTakePhoto = ({ target: { files: [ file, ...rest ] } }) => {
    const parsePhoto = img => new Promise((resolve, reject) => {
      const uploadId = uuid()
      dispatch({  
        type: 'ADD',
        id: uploadId
      })
      const dataUrl = img.toDataURL()
      savePhoto(id, subId, dataUrl)
        .then(() => {
          dispatch({
            type: 'UPLOADED',
            id: uploadId
          })
        })
    })

    loadImage(file, parsePhoto, {
			orientation: true,
			pixelRatio: 1,
			maxWidth: 1920,
			maxHeight: 1080
		})
  }

  if (!subtask || !subtask.id) {
    return null
  }


  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={handleTakePhoto}
        capture="environment"
        id="capture"
        className={classes.hiddenInput}
        disabled={subtask.state === 'DONE'}
      />
      <Dialog
        open={showWarning}
        onClose={() => setShowWarning(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please note</DialogTitle>
        <DialogContent>
          <DialogContentText component="div" id="alert-dialog-description">
            <Typography variant="body1">Remember the following when taking photos:</Typography>
            <Typography variant="body2" component="div">
              <ul>
                <li>Only submit photos taken in daylight</li>
                <li>Set focus when taking photos of signs</li>
                <li>If in doubt, take one more</li>
                <li>Always mark a task as completed, when all photos have been submitted</li>
              </ul>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarning(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Fade in timeout={500}>
        <div className={classes.root}>
          <div className={classes.header}>
            <Button component={Link} to={`/${id}`} className={classes.buttonLeft}>
              <BackIcon className={classes.icon} />
            </Button>
            <Typography className={classes.title} variant="h6">{subtask.title}</Typography>
            <FormControlLabel control={<Switch value="done" onChange={handleSwitchChange} checked={subtask.state === 'DONE'}/>} label="Completed" className={classes.buttonRight} labelPlacement="start" />
          </div>
          <div className={classes.images}>
            {!!subtask.photos.length && subtask.photos.map((photo, index) => (
              <img key={index} className={classes.image} src={photo} alt="Entry" />
            ))}
            {!subtask.photos.length && (
              <Typography variant="h6" className={classes.message}>No photos have been attached to this assignment yet.</Typography>
            )}
          </div>
        </div>
      </Fade>
      <Slide in={subtask.state !== 'DONE'} direction="up">
        <BottomNavigation className={classes.bottomNav}>
          <BottomNavigationAction component="label" htmlFor="capture" label="Tag billede" icon={isUploading ? <CircularProgress color="secondary" /> : <CameraIcon className={classes.openCamera}/>} />
        </BottomNavigation>
      </Slide>
    </>
  )
}
