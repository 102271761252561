import React, { useState, createContext, useContext, useEffect } from 'react'
import { auth } from 'backend'

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  
  const signIn = ({ email, password }) => auth
    .signInWithEmailAndPassword(email, password)
    .then(response => {
      setUser(response.user)
      return response.user
    })

  const signOut = () => auth
    .signOut()
    .then(() => {
      setUser(false)
    })

  const sendPasswordResetEmail = email => auth
    .sendPasswordResetEmail(email)
    .then(() => true)

  const confirmPasswordReset = ({ code, password }) => auth
    .confirmPasswordReset(code, password)
    .then(() => true)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(false)
      }
    })

    return () => unsubscribe()
  }, [])

  return {
    user,
    signIn,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset
  }
}