import React from 'react'
import { AppBar, Toolbar, Typography, Slide, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1)
  },
  signOut: {
    color: 'white',
    margin: theme.spacing(1),
    marginLeft: 'auto'
  }
}))

export default () => {
  const classes = useStyles()
  const { user, signOut } = useAuth()

  return (
    <>
      <Slide direction="down" in={!!user}>
        <AppBar position="fixed" className={classes.root}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>tasq.me</Typography>
            <Button onClick={signOut} className={classes.signOut}>Sign out</Button>
          </Toolbar>
        </AppBar>
      </Slide>
      {user && <Toolbar />}
    </>
  )
}