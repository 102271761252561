import React, { useState } from 'react'
import {
  Container,
  Paper,
  Button,
  TextField,
  Snackbar
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    alignItems: 'center'
  },
  logo: {
    width: '150px',
    height: '150px',
    background: 'url(logo512.png) no-repeat center',
    backgroundSize: 'contain',
    marginBottom: theme.spacing(3)
  },
  input: {
    marginBottom: theme.spacing(3)
  },
  submitButton: {
    marginTop: theme.spacing(2)
  },
  snackbar: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))

export default () => {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center'
  })

  const handleClose = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      open: false
    }))
  }

  const handleSignIn = () => {
    const { from } = location.state || { from: { pathname: '/' } }
    auth.signIn({ email, password })
    .then(() => {
      history.replace(from)
    })
    .catch(({ message }) => {
      setSnackbar((prevState) => ({
        ...prevState,
        open: true,
        message
      }))
    })
  }

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.logo} />
        <TextField
          className={classes.input}
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          fullWidth
          type="email"
        />
        <TextField
          className={classes.input}
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          fullWidth
          type="password"
        />
        <Button className={classes.submitButton} fullWidth variant="contained" color="primary" onClick={handleSignIn}>Sign in</Button>
      </Paper>
      <Snackbar {...snackbar} className={classes.snackbar} onClose={handleClose} />
    </Container>

  )
}