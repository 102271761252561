import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Task from 'Components/Task'
import List from 'Components/List'
import Capture from 'Components/Capture'
import Splash from 'Components/Splash'
import AppBar from 'Components/AppBar'
import SignIn from 'Components/SignIn'
import useStream from 'hooks/useStream'
import { streamTasks } from 'api/task'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { ProvideAuth, useAuth } from 'hooks/useAuth'
import { Container } from '@material-ui/core'

const theme = createMuiTheme({})

function App() {
  const tasks = useStream(streamTasks, [])

  return (
    <Container maxWidth="sm">
      <ThemeProvider theme={theme}>
        <ProvideAuth>
          <Router>
            <Route path="/" render={props => <AppBar {...props} />} />
            <Switch>
              <Route exact path="/signin" render={(props) => <SignIn {...props} />} />
              <PrivateRoute exact path="/:id/:subId" render={(props) => <Capture {...props} tasks={tasks} />} />
              <PrivateRoute exact path="/:id" render={(props) => <Task {...props} tasks={tasks} />} />
              <PrivateRoute exact path="/" render={(props) => <List {...props} tasks={tasks} />} />
            </Switch>
          </Router>
        </ProvideAuth>
      </ThemeProvider>
    </Container>
  );
}

const PrivateRoute = ({ render, ...rest }) => {  
  const [authInitialized, setAuthInitialized] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (user !== null) {
      setAuthInitialized(true)
    }
  }, [user])

  if (authInitialized) { 
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user ?
          render()
          : <Redirect to={{ pathname: '/signin', state: { from: location } }} />
        }
      >
      </Route>
    )
  } else {
    return <Splash />
  }

}

export default App;
