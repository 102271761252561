import { useState, useEffect } from 'react'

export default (streamCreator, initialState) => {
  const [result, setResult] = useState(initialState)

  useEffect(() => {
    const watcher = streamCreator()
      .subscribe(setResult)

    return () => watcher.unsubscribe()
  }, [streamCreator])

  return result
}
