import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = process.env.NODE_ENV === 'development' ? ({
  apiKey: 'AIzaSyCYQjdM0xJv9gdwwCtmUg8zDh_TB-mlcjQ',
  authDomain: 'playmaker-capture-proto.firebaseapp.com',
  databaseURL: 'https://playmaker-capture-proto.firebaseio.com',
  projectId: 'playmaker-capture-proto',
  storageBucket: 'playmaker-capture-proto.appspot.com',
  messagingSenderId: '720906539275',
  appId: '1:720906539275:web:2ef3a34b2ee87cac2b920e'
}) : ({
  apiKey: "AIzaSyA9oMLQ95Fz6p_cJJA8c1EbABb1nGsCUuk",
  authDomain: "app.tasq.me",
  databaseURL: "https://tasq-prod.firebaseio.com",
  projectId: "tasq-prod",
  storageBucket: "tasq-prod.appspot.com",
  messagingSenderId: "629784617776",
  appId: "1:629784617776:web:667560089bd47fadbadef5",
  measurementId: "G-HYWTN866MG"
})

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()

// Used for accessing field values
export const firestore = firebase.firestore

//Enable offline
// firebase.firestore().enablePersistence().catch(
//   (err) => {
//     console.log(err.code)
//   }
// )
