import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress, Fade } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    backgroundColor: 'white',
    zIndex: 9999,
    color: '#36D9FF',
    padding: theme.spacing(4)
  },
  progress: {
    width: '80px',
    margin: '0 auto',
    color: '#36D9FF'
  },
  logo: {
    width: '100px',
    height: '100px',
    background: 'transparent url(logo192.png)',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto'
  }
}))

export default () => {
  const classes = useStyles()

  return (
    <Fade in timeout={{ entry: 0, exit: 200 }}>
      <div className={classes.root}>
        <div className={classes.logo} />
        <LinearProgress className={classes.progress} />
      </div>
    </Fade>
  )
}