import { db, storage } from 'backend'
import { docData, collectionData } from 'rxfire/firestore'
import { getDownloadURL } from 'rxfire/storage'
import { map, switchMap } from 'rxjs/operators'
import { combineLatest, of } from 'rxjs'

export function streamTasks () {
  return collectionData(
    db.collection('tasks'),
    'id'
  )
    .pipe(map(ts => ts.map(t => ({
      ...t,
      expire: t.expire ? new Date(t.expire.seconds * 1000) : null
    }))))
}

export function submitTask (id) {
  return db.runTransaction(transaction => {
    const ref = db.collection('tasks').doc(id)
    return transaction.get(ref)
    .then(doc => transaction.update(ref, { state: 'SUBMITTED' }))
  })
}

export function setState (id, subId, state) {
  return db.runTransaction(transaction => {
    const ref = db.collection('tasks').doc(id)
    return transaction.get(ref)
    .then(doc => {
      const { subtasks } = doc.data()
      const updatedSubtasks = subtasks.map(st => {
        if(st.id !== subId) { return st }
        else {
          return {
            ...st,
            state
          }
        }
      })
      return transaction.update(ref, { subtasks: updatedSubtasks })
    })
  })
}

export function streamPhotos (subtask, id) {
  /*
    This function takes a subtask obj and returns an array of { subtaskId, photos[] }
  */
  if (!subtask || !subtask.photos) {
    console.log('No subtask provided')
    return of([])
  }

  return combineLatest(
    ...subtask.photos.reverse().map((path) =>
      getDownloadURL(storage.ref(`/photos/${id}/${subtask.id}/${path}`))
    )
  )
}

export function streamSubTask (id, subId) {
  return docData(
    db.collection('tasks').doc(id),
    'id'
  )
    .pipe(
      switchMap(
        task => {
          const sub = task.subtasks.find(st => st.id === subId) || {}
          return streamPhotos(sub, task.id).pipe(map(photos => ({ ...sub, photos: photos || [] })))
        }
      )
    )
}
