import { Badge, Button, Card, CardActions, CardContent, Chip, Collapse, Divider, Fade, Typography } from '@material-ui/core'
import AlarmIcon from '@material-ui/icons/AccessAlarm'
import AwardIcon from '@material-ui/icons/AttachMoney'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import date from 'date-and-time'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 2),
    overscrollBehavior: 'contain'
  },
  task: {
    marginBottom: theme.spacing(2)
  },
  header: {
    margin: theme.spacing(4, 0, 2)
  },
  expand: {
    marginLeft: 'auto',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  description: {
    fontWeight: 600,
    margin: theme.spacing(2, 0)
  },
  chips: {
    marginTop: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(.5)
  },
  subtasks: {
    paddingLeft: theme.spacing(3)
  }
}))

export default ({
  tasks: unfilteredTasks
}) => {
  const classes = useStyles()
  const { user } = useAuth()
  const tasks = unfilteredTasks.filter(t => t.agentId === user.uid)
  return (
    <Fade in timeout={500}>
      <div className={classes.root}>
        <TaskList tasks={tasks.filter(t => t.state === 'OPEN')} title="Open tasks" />
        <TaskList tasks={tasks.filter(t => t.state === 'SUBMITTED')} title="Submitted tasks" />
        <TaskList tasks={tasks.filter(t => t.state === 'CLOSED')} title="Completed tasks" />
      </div>
    </Fade>
  )
}

const TaskList = ({ tasks, title }) => {
  const classes = useStyles()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (tasks.length > 0) {
      setInitialized(true)
    }
  }, [tasks.length])

  return (
    <>
      <div className={classes.header}>
        <Badge color="primary" badgeContent={tasks.length}>
          <Typography variant="h5">{title}</Typography>
        </Badge>
      </div>
      {!initialized && (
        <Card className={classes.task}>
          <CardContent>
            <Skeleton height={32} width="90%" />
            <Skeleton height={12} width="60%" />
          </CardContent>
          <Divider />
          <CardActions>
            <Button disableRipple size="small" color="primary" className={`${classes.expand}`}><ExpandMoreIcon /></Button>
          </CardActions>
        </Card>
      )}
      <Fade in={initialized}>
        <div>
          {tasks.map(task => <Task {...task} key={task.id} />) }
        </div>
      </Fade>
    </>
  )
}

const Task = (task) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  return (
    <Card className={classes.task}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {task.title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {task.summary}
        </Typography>
        <div className={classes.chips}>
          {task.expire && (
            <Chip
              icon={<AlarmIcon />}
              label={date.format(task.expire, 'D. MMM. YYYY')}
              size="small"
              color="primary"
            />
          )}
          {task.award && (
            <Chip
              icon={<AwardIcon />}
              label={`${task.award} kr`}
              size="small"
              className={classes.chip}
              color="primary"
            />
          )}
        </div>
        <Collapse in={expanded}>
          <Typography variant="body2" className={classes.description}>
            {task.description}
          </Typography>
          <Typography>This task includes:</Typography>
          <ul className={classes.subtasks}>
            {task.subtasks.map(st => (
              <li key={st.id}>
                {st.title}
              </li>
              ))}
          </ul>
        </Collapse>
      </CardContent>
      <Divider />
      <CardActions>
        <Button component={Link} to={`/${task.id}`} size="small" color="primary">{ task.state === 'OPEN' ? 'Start' : 'Show'} task</Button>
        <Button disableRipple size="small" color="primary" onClick={() => { setExpanded(!expanded) }} className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}><ExpandMoreIcon /></Button>
      </CardActions>
    </Card>
  )
}
