import React, { Fragment } from 'react'
import { useParams, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Slide,
  Typography
} from '@material-ui/core'

import AlarmIcon from '@material-ui/icons/AccessAlarm'
import AwardIcon from '@material-ui/icons/AttachMoney'
import BackIcon from '@material-ui/icons/ArrowBackIos'
import CompleteIcon from '@material-ui/icons/CheckCircle'
import OpenIcon from '@material-ui/icons/ArrowForwardIos'

import date from 'date-and-time'

import { submitTask } from 'api/task'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 2)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(0, 0, 2, 1)
  },
  iconLeft: {
    marginRight: theme.spacing(1)
  },
  chips: {
    margin: theme.spacing(1, 0)
  },
  chip: {
    margin: theme.spacing(.5)
  },
  description: {
    fontWeight: 600,
    margin: theme.spacing(2, 0)
  },
  bottomNav: {
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  completeTask: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  }
}))

export default ({ tasks }) => {
  const { id } = useParams()
  const history = useHistory()

  const classes = useStyles()
  const task = tasks.find(t => t.id === id) || {}
  const subtasksCompleted = task.subtasks && task.subtasks.every(st => st.state === 'DONE')

  const handleSubmit = () => { 
    submitTask(id)
    .then(history.push('/'))
  }

  if (!task || !task.id) {
    return null
  }

  return (
    <>
      <Button component={Link} to="/" className={classes.button}>
        <BackIcon className={classes.iconLeft} />
      </Button>
      <Fade in>
        <div className={classes.root}>
          <Card key={task.id} className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {task.title}
              </Typography>
              <div className={classes.chips}>
                <Chip
                  icon={<AlarmIcon />}
                  label={date.format(task.expire, 'D. MMM. YYYY')}
                  size="small"
                  color="primary"
                />
                {task.award && (
                  <Chip
                    icon={<AwardIcon />}
                    label={`${task.award} kr`}
                    size="small"
                    className={classes.chip}
                    color="primary"
                  />
                )}
              </div>
              <Typography variant="body1">
                {task.summary}
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {task.description}
              </Typography>
            </CardContent>
          </Card>
          <Typography variant="h5" gutterBottom>Tasks:</Typography>
          <Paper>
            <List>
              {task.subtasks.map((subtask, idx) => (
                <Fragment key= { subtask.id }>
                  <TaskListItem link={`/${task.id}/${subtask.id}`} subtask={subtask} />
                  {idx !== task.subtasks.length -1 && <Divider />}
                </Fragment>
              ))}
            </List>
          </Paper>
        </div>
      </Fade>
      <Slide in={task.state === 'OPEN' && subtasksCompleted} direction="up">
        <BottomNavigation className={classes.bottomNav}>
          <BottomNavigationAction className={classes.completeTask} label="Complete task" showLabel icon={<CompleteIcon className={classes.completeTask} />} onClick={handleSubmit} />
        </BottomNavigation>
      </Slide>
    </>
  )
}

const TaskListItem = ({ subtask, link }) => (
  <ListItem button component={Link} to={link} aria-label="Open subtask">
    <ListItemText
      primary={subtask.title}
      secondary={subtask.description}
    />
    <ListItemSecondaryAction>
      <IconButton edge="end" component={Link} to={link}>
        <OpenIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
)